export const theme = {
  colors: {
    mainBlue: '#46BAE8',
    mainYellow: '#E8DE2E',
    grayBlue: '#1F789C',
    mainBlack: '#1E1E1E',
    mainWhite: '#FFFFFF'
  },
  media: {
    tablet: '@media (min-width: 700px)',
    tabletXL: '@media (min-width: 1023px)',
    laptop: '@media (min-width: 1200px)',
    laptopXL: '@media (min-width: 1400px)',
    desktop: '@media (min-width: 1600px)',
    desktopXL: '@media (min-width: 2200px)',
  },
  font: {
    mainFont: `'Kanit', sans-serif`
  }
}