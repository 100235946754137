import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const FooterWrapper = styled.footer`
  width: 100%;
  margin: 1rem 0 0;
  padding: 2rem 1rem;
  color: ${({theme}) => theme.colors.mainWhite};
  background: ${({theme}) => theme.colors.mainBlack};
`;

const InnerWrapper = styled.div`
  width: 100%;

  ${({theme}) => theme.media.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  ${({theme}) => theme.media.tabletXL} {
    padding: 0 2rem;
  }

  ${({theme}) => theme.media.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 2rem 2rem 0;
    max-width: 1200px;
    margin: 0 auto;
  }

  ${({theme}) => theme.media.desktop} {
    max-width: 1400px;
    grid-gap: 4rem;
  }
`;

const TextBox = styled.div`
  padding: 1rem;
`;

const H3 = styled.h3`
  font-size: 1.4rem;
  
  ${({theme}) => theme.media.desktop} {
    font-size: ${({isCloud}) => isCloud ? '2.1rem' : "1.6rem"};
    line-height: 1.2;
  }

  span {
    color: ${({theme}) => theme.colors.mainBlue}
  }
`;

const Text = styled.p`
font-size: 1.1rem;
font-weight: 300;

${({theme}) => theme.media.desktop} {
  font-size: 1.3rem;
}
`;

const Button = styled.button`
  font-family: ${({theme}) => theme.font.mainFont};
  display: inline-block;
  text-decoration: none;
  padding: .3rem 1rem;
  margin-top: .8rem;
  color: ${({theme}) => theme.colors.mainWhite};
  border: 2px solid #46BAE8;
  letter-spacing: 1px;
  font-weight: 500;
  box-shadow: 3px 3px 0 0 ${({theme}) => theme.colors.mainWhite};
`;

const ImgWrapper = styled.div`
  width: 7rem;
  margin-bottom: 1rem;

  ${({theme}) => theme.media.laptop} {
    width: 9rem;
  }
`;

const BrandBox = styled.div`
  padding: 1rem;
`;

const UlBrand = styled.ul`
  margin-top: .7rem;
`;

const ButtonBrand = styled.button`
  display: block;
  text-decoration: none;
  color: ${({theme}) => theme.colors.mainWhite};
  font-size: 1.1rem;

  ${({theme}) => theme.media.desktop} {
    font-size: 1.2rem;
  }
`;

const NavBox = styled.div`
  padding: 1rem;
`;

const UlNav = styled.ul`
  margin-top: .7rem;

  a {
    display: block;
    text-decoration: none;
    color: ${({theme}) => theme.colors.mainWhite};
    font-size: 1.1rem;

    ${({theme}) => theme.media.desktop} {
      font-size: 1.2rem;
    }
  }
`;

const Copyright = styled.div`
  font-size: .7rem;
  margin-top: 1rem;
  text-align: center;
`;


const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      logoBottomImg: file(relativePath: { eq: "ePulpit24-logo-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper>
      <InnerWrapper>

        <TextBox>
          <ImgWrapper>
            <Img fluid={data.logoBottomImg.childImageSharp.fluid} alt="logo e-Pulpit24"/>
          </ImgWrapper>
          <H3 isCloud="isCloud">Przeniesiemy Cię do <span>chmur</span></H3>
          <Text>Zrób krok w przyszłość pełną korzyści</Text>
          <Button 
            as={AniLink} 
            fade to="/oferta"
            onClick={e => {
              e.preventDefault()
              trackCustomEvent({
                category: "Footer Button",
                action: "Click",
                label: "Przejscie na Abonamenty w Footer",
              })
            }}
          >Sprawdź</Button>
        </TextBox>
        <BrandBox>
          <H3>Nasze marki</H3>
          <UlBrand>
            <ButtonBrand as="a" href="https://www.blusoft.pl" target="_blank" rel="noopener">BluSoft</ButtonBrand>
            <ButtonBrand as="a" href="https://www.bluserwer.pl" target="_blank" rel="noopener">Bluserwer - Hosting i serwery</ButtonBrand>
            <ButtonBrand as="a" href="https://www.bluoffice.pl" target="_blank" rel="noopener">BluOffice - Wirtualne biuro</ButtonBrand>
            <ButtonBrand as="a" href="https://blustreamtv.pl" target="_blank" rel="noopener">BluStreamTV - Transmisje online</ButtonBrand>
            <ButtonBrand as="a" href="https://www.podpis-elektroniczny.bydgoszcz.pl" target="_blank" rel="noopener">Podpis elektroniczny</ButtonBrand>
            <ButtonBrand as="a" href="https://www.360totalsecurity.pl" target="_blank" rel="noopener">360 Total Security</ButtonBrand>
          </UlBrand>
        </BrandBox>
        <NavBox>
          <H3>Nawigacja</H3>
          <UlNav>
            <AniLink fade to="/oferta">Oferta</AniLink>
            <AniLink fade to="/pulpit-dla-biura-rachunkowego">Biura rachunkowe</AniLink>
            <AniLink fade to="/onas">O nas</AniLink>
            <AniLink fade to="/pomoc-zdalna">Pomoc zdalna</AniLink>
            <AniLink fade to="/kontakt">Kontakt</AniLink>
          </UlNav>
        </NavBox>

      </InnerWrapper>
      <Copyright>
        <p>&copy; 2020 Wszystkie prawa zastrzeżone e-Pulpit24</p>
      </Copyright>
    </FooterWrapper>
  )
}

export default Footer