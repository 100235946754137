// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-oferta-js": () => import("./../../../src/pages/oferta.js" /* webpackChunkName: "component---src-pages-oferta-js" */),
  "component---src-pages-onas-js": () => import("./../../../src/pages/onas.js" /* webpackChunkName: "component---src-pages-onas-js" */),
  "component---src-pages-pomoc-zdalna-js": () => import("./../../../src/pages/pomoc-zdalna.js" /* webpackChunkName: "component---src-pages-pomoc-zdalna-js" */),
  "component---src-pages-pulpit-dla-biura-rachunkowego-js": () => import("./../../../src/pages/pulpit-dla-biura-rachunkowego.js" /* webpackChunkName: "component---src-pages-pulpit-dla-biura-rachunkowego-js" */)
}

