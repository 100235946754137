import React from "react"
import PropTypes from "prop-types"
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import { theme } from '../utils/theme'

import Menu from '../components/menu';
import Footer from '../components/footer';

const GlobalStyle = createGlobalStyle`
*, *::before, ::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: 'Kanit', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}

body::-webkit-scrollbar-thumb {
  background-color: #1E1E1E;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`;

const MainWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const MainBody = styled.main`
  padding-top: 3rem;
  overflow: hidden;
`;


const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle/>
        <Helmet>
          <link href="https://fonts.googleapis.com/css?family=Kanit:200,300,400,500,600,700,800,900&display=swap&subset=latin-ext" rel="stylesheet"/>
          <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
          <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        </Helmet>
        <MainWrapper>
          <Menu />
          <MainBody>{children}</MainBody>
          <Footer />
        </MainWrapper>
      </>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
