import React, { useState, useRef } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import gsap from 'gsap'
import styled from 'styled-components'
import Img from "gatsby-image"
import AniLink from "gatsby-plugin-transition-link/AniLink";


const MenuWrapper = styled.div`
  position: fixed;
  align-items: center;
  z-index: 10;
  width: 100%;
  padding: 1rem 2rem;
  background: ${({theme}) => theme.colors.mainBlack};
  display: grid;
  grid-template-columns: auto 1fr;

  ${({theme}) => theme.media.laptop} {
    padding: 1rem 8rem;
  }

  ${({theme}) => theme.media.desktop} {
    padding: 1rem 16rem;
  }

  ${({theme}) => theme.media.desktopXL} {
    padding: 1.1rem 25rem;
  }
`;

const LogoImg = styled.div`
  width: 7rem;
`;

const MenuNav = styled.div`

`;

const Burger = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 1rem;

    :focus {
      outline: none;
    }
  }
  span {
    display: inline-block;
    width: 2rem;
    height: 3px;
    margin: 0.2rem 0;
    background: ${({theme}) => theme.colors.mainWhite}
  }

  ${({theme}) => theme.media.laptop} {
    display: none;
  }
`;

const Navigaction = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transform: translateY(-100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  z-index: -1;
  background: ${({theme}) => theme.colors.mainBlack};

  ul {
    text-align: end;
  }

  ${({theme}) => theme.media.laptop} {
    position: relative;
    width: 100%;
    height: auto;
    transform: translateY(0);
    z-index: 5;

    ul {
      display: flex;
    }
  };
`;

const NavLinks = styled.li`
  padding: .5rem 2rem;
  list-style: none;

  ${({theme}) => theme.media.tablet} {
    padding: .8rem 4rem;
  }

  ${({theme}) => theme.media.laptop} {
    padding: .5rem 2rem;
  }

  a {
    text-decoration: none;
    font-size: calc(3.18182vw + 21.81818px);
    font-weight: 500;
    transition: .3s;
    color: ${({theme}) => theme.colors.mainWhite};

    ${({theme}) => theme.media.laptop} {
      font-size: 1.1rem;
      font-weight: 600;
    }
  }

  a.active {
    color: ${({theme}) => theme.colors.mainBlue};
  }

  a:hover {
    color: ${({theme}) => theme.colors.mainBlue}
  }
`;

const Menu = () => {
  const data = useStaticQuery(graphql`
    query {
      logoImg: file(relativePath: { eq: "ePulpit24-logo-white.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    const [open, setOpen] = useState(true);
    const mobileMenu = useRef(null)
    const mobileMenuLi = useRef(null)
    const spans = useRef(null)


    const openMenu = () => {

      const windowSize = window.innerWidth;

      if(windowSize < 1200) {

      const wrapperMenu = mobileMenu.current;
      const elements = mobileMenuLi.current.children;

      const burgerAnim = spans.current.children;
      const burgerAnimArr = [...burgerAnim]

      const firstSpan = burgerAnimArr[0]
      const secondSpan = burgerAnimArr[1]
      const thirdSpan = burgerAnimArr[2]

      gsap.set(elements, {autoAlpha: 0})
      const tlOpen = gsap.timeline();
      const tlBurger = gsap.timeline();

      if(open) {
        tlOpen.to(wrapperMenu, {duration: .6, y: 0})
              .to(elements, {duration: .2, autoAlpha: 1, stagger: 0.1})
        setOpen(false)
      } else if(!open) {
        tlOpen.to(wrapperMenu, {duration: .6, y: '-100%'})
              .to(elements, {duration: .2, autoAlpha: 0, stagger: 0.1})
        setOpen(true)
      }

      if(!open) {
        tlBurger.to(firstSpan,  {duration: .3, rotate: 0, y: 0})
                .to(thirdSpan,  {duration: .3, rotate: 0, y: 0}, '-=0.3')
                .to(secondSpan,  {duration: .3, x: 0, autoAlpha: 1}, '-=0.3')

      } else if(open) {
        tlBurger.to(firstSpan,  {duration: .3, rotate: '45deg', y: 10})
                .to(thirdSpan,  {duration: .3, rotate: '-45deg', y: -9}, '-=0.3')
                .to(secondSpan,  {duration: .3, x: 25, autoAlpha: 0}, '-=0.3')

      }
    }
    }

  return (
    <MenuWrapper>
      <LogoImg>
        <AniLink fade to="/"><Img fluid={data.logoImg.childImageSharp.fluid} alt="logo e-Pulpit24"/></AniLink>
      </LogoImg>
      <MenuNav>
        <Burger>
          <div role="button" onClick={openMenu} tabIndex={0} ref={spans}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Burger>
        <Navigaction ref={mobileMenu}>
          <ul ref={mobileMenuLi}>
            <NavLinks><AniLink activeClassName="active" fade to="/oferta" onClick={openMenu}>Oferta</AniLink></NavLinks>
            <NavLinks><AniLink activeClassName="active" fade to="/pulpit-dla-biura-rachunkowego" onClick={openMenu}>Biura rachunkowe</AniLink></NavLinks>
            <NavLinks><AniLink activeClassName="active" fade to="/pomoc-zdalna" onClick={openMenu}>Pomoc zdalna</AniLink></NavLinks>
            <NavLinks><AniLink activeClassName="active" fade to="/onas" onClick={openMenu}>O nas</AniLink></NavLinks>
            <NavLinks><AniLink activeClassName="active" fade to="/kontakt" onClick={openMenu}>Kontakt</AniLink></NavLinks>
          </ul>
        </Navigaction>
      </MenuNav>
    </MenuWrapper>
  )
}

export default Menu